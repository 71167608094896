<template lang="pug">
.all
  BorderGradient(noPadding=true).all


    .instructions(v-if="!isStreamStart && !isFull")
      .container 
        .box
          h3 {{ upperCase($t("videoTaking.instructions.title")) }}
          br
          span {{$t("videoTaking.instructions.list.1")}}
          br
          br
          span {{$t("videoTaking.instructions.list.2.title")}} 
          br
          ul
            li {{$t("videoTaking.instructions.list.2.list.1")}}
            li {{$t("videoTaking.instructions.list.2.list.2")}}
            li {{$t("videoTaking.instructions.list.2.list.3")}}
          br
          span {{$t("videoTaking.instructions.list.3")}}
          br 
          br
          .link(@click="backMorpho") {{$t("videoTaking.instructions.remind")}}


    .loader(v-if="isLoading", :style="dynamicStylesVideo")

      button.instructions-btn(@click="toggleInstructionsOn", v-if="buttonVisibility")
        v-icon {{ this.dynamicStylesVideo.icButton }}

      div(v-if="contentDisplayed")

        .message-begin(class="isRegular")
          v-icon.loading-icon(:color='this.colors.white', size="39") mdi-loading
          span(style="text-align: center;") {{ loadingMessage }}
        

    .videoTaking(v-else, :class="{ 'error-border': isPaused && isStreamStart}", :style="dynamicStylesVideo")

      button.instructions-btn(@click="toggleInstructionsOn", v-if="buttonVisibility")
        v-icon {{ this.dynamicStylesVideo.icButton }}

      .videoFeature.d-flex.justify-center.align-center
        //- img.videoOn(ref="myVisualisationStream")
        video.videoOn(ref="myVisualisationStream",autoplay, controls=false)

      div(v-if="contentDisplayed")

        v-card-actions.actionGroup

          v-btn(:disabled="isDisabled" v-if="isVideoOn", @click="toggleIsVideoOn")
            v-icon mdi-video
          v-btn(v-else, @click="toggleIsVideoOn")
            v-icon mdi-video-off
            
          v-btn.mx-3(x-large, :disabled="isDisabledRec || !allAiInitialized", :color="this.colors.magenta", @click="triggerMorphoEvent", v-if="!isStreamStart")
            v-icon(x-large) mdi-record-rec
          //- TODO: USE THIS "isPaused" WHEN MORPHO SEND DATA BACK TO FRONT FOR LIGHTING ERRORS
          //- v-btn.mx-3(x-large, :disabled="isPaused || !allAiInitialized", :color="this.colors.magenta", @click="triggerMorphoEvent", v-if="!isStreamStart")
          //-   v-icon(x-large) mdi-record-rec 
          v-icon(:color="this.colors.white", size="60", class="rotate" v-else) mdi-reload

        .params
          v-icon(:color="!allAiInitialized ? this.colors.white : errorNetwork ? this.colors.bad : this.colors.good", size="30", class="ic-status") mdi-wifi
          span.isRegular {{$t("videoTaking.instructions.options.connection")}}
          br

          v-icon(:color="!allAiInitialized ? this.colors.white : errorLight ? this.colors.bad : this.colors.good", size="30", class="ic-status") mdi-lightbulb-on
          span.isRegular {{$t("videoTaking.instructions.options.light")}}
          br

          v-icon(:color="!allAiInitialized ? this.colors.white : errorFraming ? this.colors.bad : this.colors.good", size="30", class="ic-status") mdi-camera-marker
          span.isRegular {{$t("videoTaking.instructions.options.framing")}}
          br

        .message-begin(v-if="!allAiInitialized", class="isRegular", style="flex-direction: column;")
          span(style="text-align: center;") {{$t("videoTaking.initialization")}}

        .message-begin(v-else-if="!isStreamStart && !isPaused", class="isRegular", style="flex-direction: column;")
          span(style="text-align: center;") Temps estimé :
          span(style="text-align: center;") {{ this.timerMin }} à {{ this.timerMax }} s

        .message-begin.blink(v-else-if="isPaused")
          v-icon(v-if="errorNetwork", :color="this.colors.bad", size="110") mdi-wifi
          v-icon(v-else-if="errorLight", :color="this.colors.bad", size="110") mdi-lightbulb-on
          v-icon(v-else, :color="this.colors.bad", size="110") mdi-camera

        .timer(v-else, class="isRegular", style="flex-direction: column;")
          span(style="text-align: center;") {{ this.timer }} s

</template>



<script>
import Vuex from "vuex";

import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  name: "videoTaking",

  props: ['upperCase', 'isStreamStart'],

  components: {
    BorderGradient,
  },
  data: () => ({
    dynamicStylesVideo: {
      icButton: 'mdi-arrow-left',
      width: 'calc(100% - 270px)',
      left: '270px',
      icButtonFull: 'mdi-arrow-right',
      widthFull: '100%',
      leftFull: '0px',
      widthRight: 'calc(100% - 270px)',
      leftRight: '270px',
      icButtonRight: 'mdi-arrow-left',

    },
    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      magenta: '#ff00ff',
      black: '#000000',
      blackalt: '#010220',
      white: '#FFFFFF',
    },

    isVideoOn: false,//correspond à l'image de la camera barré ou non


    isDisabled: false,//mettre a true si on veut pouvoir activer le bouton dès le debut
    isDisabledRec: true,//mettre a true si on veut pouvoir activer le bouton rec dès le debut

    load: true,
    inVideoRef: null,
    outVideoRef: null,
    MorphoRequestSent: false,
    // allAiInitialized: true, // switched to true when all AI are ready to operate
    // results: 'badResults', // the value of this string depend on the state of the capture (inactivity, or badResults or results ok or other if the capture is in live)
    // connexionLoading: true, // true if we are are trying to establish a connexion with remote peer
    // timerCompute: 10, // the timer which gives us an overview of the time remaining before the results are displayed
    // timer: 30, // the main timer in charge of displaying the time remaining to finish the capture
    
    // timerMin: 25, // the minimum time estimated before the capture
    // timerMax: 35, // the maximum time estimated before the capture
    // errorFraming: false, // true if there is a problem with the framing
    // errorLight: true, // true if there is a problem with the light
    // errorNetwork: false, // true if there is a problem with the network

    isStreamStart: false, // to know if the stream has begun
    minWidth: 600, // the minimum width to display both an instructions and content to the user
    isFull: false, // is the user in fullscreen ?
    buttonVisibility: true, // Control the visibility of the button that displays the instructions. 
    succes: false, //TODO mettre à true lors de la mise en place des erreurs
  }),

  methods: {
    ...Vuex.mapActions({
      initCommunication: "communication/initCommunication",
      emitClientReady: "communication/emitClientReady",
      sendMorphoRequest: "communication/sendMorphoRequest",
      setAsSelectedState: "states/selectState",
      setConnexionLoading: "cmdRemotePeer/setConnexionLoading",
      setTimer: "cmdRemotePeer/setTimer",
      setTimerCompute: "cmdRemotePeer/setTimerCompute",
      setErrorLight: "cmdRemotePeer/setErrorLight",
      disconnectSocket: "communication/disconnectSocket",
      stopStreamSoct: "communication/stopStreamSoct",
    }),

    updateIsStreamStart(value) {
      this.$emit('update:isStreamStart', value)
    },


    toggleInstructionsOn() {
      if (this.isFull) {
        this.dynamicStylesVideo.left = this.dynamicStylesVideo.leftRight;
        this.dynamicStylesVideo.width = this.dynamicStylesVideo.widthRight;
        this.dynamicStylesVideo.icButton = this.dynamicStylesVideo.icButtonRight;
      }
      else {
        this.dynamicStylesVideo.left = this.dynamicStylesVideo.leftFull;
        this.dynamicStylesVideo.width = this.dynamicStylesVideo.widthFull;
        this.dynamicStylesVideo.icButton = this.dynamicStylesVideo.icButtonFull;
      }
      this.isFull = !this.isFull;
    },

    backMorpho() {
      this.$emit('backMorpho-event')
    },

    getInVideoRef() {
      return this.inVideoRef;
    },

    getIsVideoOn() {
      console.log("morphoDebut")
      return this.isVideoOn;

    },
    //setters
    setInVideoRef(value) {
      this.inVideoRef = value;
    },
    setOutVideoRef(value) {
      this.outVideoRef = value;
    },
    setIsVideoOn(value) {
      this.isVideoOn = value;
    },

    toggleIsVideoOn() {
      this.isVideoOn = !this.isVideoOn;
      if (this.isVideoOn) {
        // this.startStream();
      } else {
        this.stopStreamSoct();
        this.disconnectSocket()
        this.isDisabledRec = true;
        this.PoseRequestSent = false;
      }
    },

    // async toggleIsVideoOn() {
    //   this.isVideoOn = !this.isVideoOn;
    //   await this.isVideoOn ? this.setAsSelectedState(this.states[3]) : this.setAsSelectedState(this.states[2]);
    // },

    startTimerCompute() {
      // Set the initial value for timerCompute (e.g., 10 seconds)
      // this.timerCompute = 10; 

      // Use setInterval to decrease the timer every second
      const interval = setInterval(() => {
        if (this.timerCompute > 0) {
          this.timerCompute--;
        } else {
          clearInterval(interval); // Stop the interval when timerCompute reaches 0
        }
      }, 1000); // 1000 ms = 1 second
    },


    // async startStream() {
    //   console.log("startStream");
    //   this.dynamicStylesVideo.width = this.dynamicStylesVideo.widthFull;
    //   this.dynamicStylesVideo.left = this.dynamicStylesVideo.leftFull;
    //   this.buttonVisibility = false;
    //   this.updateIsStreamStart(true);

    //   // Start the timer for AI processing countdown
    //   this.startTimerCompute(); // Start the timer when recording starts


    //   //const AppState={//on y rangera les commandes de changement d'etats
    //   //commandState: {}
    //   //}

    //   /*const getters = {
    //     StateParam: (AppState) => AppState.commandState 
    //   };*/
    //   /*
    //   const newMorphoCommand=[]
    //   console.log("MORPHO")
    //   console.log(newMorphoCommand)
      
    //   let actualListOfCommand = JSON.parse(localStorage.getItem('commands'))
    //   console.log(actualListOfCommand)
    //   console.log(AppState)
      
      
    //   console.log(actualListOfCommand)

    //   console.log(newMorphoCommand)
    //   //updateListOfCommand(AppState,actualListOfCommand)
    //   const morphoTakingExists = actualListOfCommand.includes("MorphoTaking");
    //   const PoseTakingExists = actualListOfCommand.includes("PoseTaking");

    //   if (PoseTakingExists) {//si il y avait une commande de pose, on l'enleve 
    //     actualListOfCommand.shift()
      
    //     localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
    //     AppState.commandState = JSON.parse(JSON.stringify(actualListOfCommand))
    //   }
    //   if (!morphoTakingExists) {
    //     actualListOfCommand.unshift("MorphoTaking")
      
    //     localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
    //     AppState.commandState = JSON.parse(JSON.stringify(actualListOfCommand))
    //   }
    
    //   //AppState.commit('UPDATE_LIST_OF_COMMAND', actualListOfCommand)
      
      
    
    //   console.log(newMorphoCommand)
      
      
    //   console.log('newMorphoCommand STRING', newMorphoCommand)
    //   */
    //   /*if (this.isVideoOn) {
    //     this.isStreamStart = !this.isStreamStart
    //     this.errorFraming = false
    //     this.errorLight = false
    //     this.timer = 5
    //     setTimeout(() => {this.decreaseTimer();},1000);
    //     setTimeout(() => {
    //       this.decreaseTimer()
    //     }, 6000)
      
    //   }
    //   else {
    //     console.log("Error, video is off")
    //   }*/
    //   /*if (
    //     "mediaDevices" in navigator &&
    //     "getUserMedia" in navigator.mediaDevices
    //   ) */

    //   {

    //     console.log("nav", navigator);
    //     console.log("MORPHO2")
    //     console.log("media", navigator.mediaDevices);
    //     const mediaStream = await navigator.mediaDevices.getUserMedia({
    //       video: true,
    //     });
    //     // const $inVideo = this.getInVideoRef();
    //     const $outVideo = this.getOutVideoRef();

    //     $outVideo.srcObject = mediaStream;
    //     $outVideo.play();

    //     await this.initCommunication({
    //       webcamStream: mediaStream,
    //       components: this,
    //     });
    //     await this.emitClientReady();

    //   }

    // },

    triggerMorphoEvent() {

      // console.log('==================poseDatas==================');
      // console.log(this.poseDatas);
      // console.log('====================================');
      // alert(this.isDisabledRec);

      if(this.PoseRequestSent == true) {
        return;
      }
        try {
          this.sendMorphoRequest(this.morphoDatas);
          console.log('==================this.morphoDatas==================');
          console.log(this.morphoDatas);
          console.log('====================================');
          this.MorphoRequestSent = true;
          // this.$store.getters['communication/signalingClient'].getSocket().emit("PoseRequest");
        console.log("Morpho event and data emitted");
        // });
        } catch (error) {
          console.log('====================================');
          console.log('triggerMorphoEvent AND sendMorphoData ERROR: ' + error);
          console.log('====================================');
        }


      },

    async startStream() {
      if (this.MorphoRequestSent == true) {
        return;
      }
      console.log("startStream ------");
      try {
        await this.initCommunication(this);
      } catch (error) {
        console.log('====================================');
        console.log('initCommunication ERROR: ' + error);
        console.log('====================================');
      }
      await this.emitClientReady();

      try {
        // console.log('==================sendDataToMorpho==================');
        // this.sendMorphoData(data)
        this.$store.getters['communication/signalingClient'].getSocket().on('consumer ready', () => {
          // this.sendMorphoData(data);
          console.log('==================isDisabledRec==================');
          console.log(this.isDisabledRec);
          console.log('====================================');
          this.isDisabledRec = false;
        });
      } catch (error) {
        console.log('====================================');
        console.log('ERROR: ' + error);
        console.log('====================================');
      }
     
      
      let actualListOfCommand = JSON.parse(localStorage.getItem('commands'))

      const PoseTakingExists = actualListOfCommand.includes("PoseTaking");

      const morphoTakingExists = actualListOfCommand.includes("MorphoTaking");

      if (morphoTakingExists) {//si il y avait une commande de morpho, on l'enleve
        actualListOfCommand.shift()
        console.log('enleveCommandeMorpho')
        localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
      }
      if (!PoseTakingExists) {
        actualListOfCommand.unshift("PoseTaking")
        console.log('ajoutCommandePose')
        localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
      }
      
         // Check the video resolution after a short delay to ensure the stream has started
    // setTimeout(this.checkVideoResolution, 3000);
    },

    handleStreamLoaded() {
      // Now you can safely access this.$refs.myVisualisationStream
      this.$nextTick(() => {
        console.log('Component mounted.');
      this.setInVideoRef(this.$refs.myVisualisationStream);
      console.log('=================this.$refs.myVisualisationStream===================');
      console.log(this.$refs.myVisualisationStream);
      console.log('====================================');
      this.setOutVideoRef(this.$refs.myStream);
      console.log('Video refs set.');
        // Additional logic to handle the video element
      });
    },

  },




  computed: {
    ...Vuex.mapGetters({
      peer: "communication/peerClient",
      states: "states/list",

      allAiInitialized: "cmdRemotePeer/allAiInitialized",
      results: "cmdRemotePeer/results",
      connexionLoading: "cmdRemotePeer/connexionLoading",
      timerCompute: "cmdRemotePeer/timerCompute",
      timer: "cmdRemotePeer/timer",
      timerMin: "cmdRemotePeer/timerMin",
      timerMax: "cmdRemotePeer/timerMax",
      errorFraming: "cmdRemotePeer/errorFraming",
      errorLight: "cmdRemotePeer/errorLight",
      errorNetwork: "cmdRemotePeer/errorNetwork",

    }),
    ...Vuex.mapState(['morphoDatas']),
    ...Vuex.mapState(['poseDatas']),

    contentDisplayed() { // control if the content superposed to the capture is displayed
      return (this.screenWidth < this.minWidth && this.isFull) || this.screenWidth > this.minWidth;
    },

    screenWidth() {
      return screen.width;
    },

    loadingMessage() { // when there is a loading screen return the good message to display 
      return this.isStreamStart ? `Résultats dans ${this.timerCompute} s...` : "Connexion...";
    },

    isLoading() { // control the loading screen generally
      // return this.computeLoading || this.connexionLoading;
      return this.connexionLoading;
    },

    computeLoading() { // control the loading after the main timer
      return this.timer <= 0;
    },

    isPaused() { // control the pause of the capture generally
      return (this.errorFraming || this.errorLight || this.errorNetwork) ? true : false;
    },

  },
  async mounted() {

    //  utiliser setConnexionLoading et setTimeout pour verifier(recevoir si) que la machine morpho est prets
    setTimeout(() => {
      this.setConnexionLoading(false);
    this.load = false;

    }, 3000);


    this.$watch('load', (newValue) => {
      if (!newValue) {
        console.log('================this.load====================');
        console.log(this.load);
        console.log('====================================');
        this.handleStreamLoaded();
      }
    });

    setTimeout(() => {
      this.setTimer(0);
    }, 20000);

    // setTimeout(() => {
    //   this.setTimerCompute(0);
    // }, 23000);

    setTimeout(() => {
      this.setErrorLight(false);
    }, 5000);



  },


  watch: {

    isVideoOn: function (val) {
      if (!val) this.stopStream();
      else this.startStream();
    },

    timerCompute: function (val) {
      if (this.results === 'resultsOk' || this.results === 'badResults') {
        this.updateIsStreamStart(false);
      }
      if (val == 0)
        this.$emit(this.results === 'resultsOk' ? 'resultsOk-event' : this.results === 'badResults' ? 'badResults-event' : 'inactivity-event')
    }

  },
}

</script>



<style lang="scss" scoped>
$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;


@mixin indicator {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin msg-begin {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

@mixin timer {
  font-style: normal;
  font-weight: 500;
  font-size: 110px;
  line-height: 144px;
}

@mixin message {
  position: absolute;
  text-shadow: 1px 1px 3px $black;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}


.videoTaking {
  background-image: url('~@/assets/test-bg/test-bg.jpg');
  background-size: cover;
  height: 100%;
  top: 0%;
  position: absolute;
}

.loader {
  background-color: $blackalt;
  background-size: cover;
  height: 100%;
  top: 0%;
  position: absolute;
}

.error-border {
  box-sizing: border-box;
  border: 5px solid $bad;
}

.regular-border {
  box-sizing: border-box;
  border: 5px solid transparent;
}

.actionGroup {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}

.backMorpho {
  border-style: solid;
  border-color: $magenta;
}

.params {
  @include indicator;
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  padding-right: 3%;
  padding-top: 3%;
  text-shadow: 1px 1px 3px $black;
}

.instructions-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px; 
  height: 40px;
  border-radius: 7px; 
  background-color: $white;
}


.isRegular {
  color: $white;
}

.isGood {
  color: $good;
}

.isBad {
  color: $bad;
}

.ic-status {
  margin-right: 10px;
  padding-bottom: 10px;
}

.message-begin {
  @include msg-begin;
  @include message;
}

.timer {
  @include timer;
  @include message
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.rotate {
  animation: spin 4s linear infinite;
}



.instructions {
  position: relative;
  height: 100%;
  width: 270px;
  

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);

    .box {
      .link {
        cursor: pointer;
        color: $magenta;
        display: inline-block;
      }
    }
  }

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  animation: spin 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

.videoFeature {
    height: 100%;
    width: 100%;
    position: relative;
  }

  @media screen and (min-width:960px) {
    video {
    height: 100%;;
    width: 60%;
    border: 2px solid white;
  }
  }

  @media screen and (max-width:960px) {
    video {
    height: 100%;;
    width: 80%;
    border: 2px solid white;
  }
  }



@media (max-width:1263px) {
  .videoTaking {
    width: 90%;
    left: 5%;
  }

  .textVideoTaking {
    z-index: 6;
    left: 10%;
    top: 13%;
    color: $white;
    position: absolute;
  }
}
</style>
