<template lang="pug">
#mainView
    SideBarL(:class="[isVisibleSideBarL ? '' : 'hidden-md-and-down']")
    BorderGradient.responsiveButton.responsiveButtonL.hidden-lg-and-up.side-bar-s(
      noPadding,
      forButton,
      :class="[isVisibleSideBarL ? 'sideBarLShow' : '', isVisibleSideBarR ? 'hidden-xxs-and-down' : '']"
    ) 
      p.mx-2.d-flex.justify-center.align-center(@click="toggleSideBarL")
        span
          v-icon(v-if="isVisibleSideBarL", color="black", size="18px") mdi-arrow-left
          v-icon(v-else, color="black", size="18px") mdi-arrow-right
         
        // {{ isVisibleSideBarL ? '<-' : '->' }}
  
    Visualisation
  
    SideBarR(:class="[isVisibleSideBarR ? '' : 'hidden-md-and-down']")
    BorderGradient.responsiveButton.responsiveButtonR.hidden-lg-and-up.side-bar-s(
      noPadding,
      forButton,
      :class="[isVisibleSideBarR ? 'sideBarRShow' : '', isVisibleSideBarL ? 'hidden-xxs-and-down' : '']"
    ) 
      p.mx-2.d-flex.justify-center.align-center(@click="toggleSideBarR")
        //- span {{ isVisibleSideBarR ? '->' : '<-' }}
        span
          v-icon(v-if="isVisibleSideBarR", color="black", size="18px") mdi-arrow-right
          v-icon(v-else, color="black", size="18px") mdi-arrow-left
    //- Alert(
    //-   v-model:dialog="alertVisible"
    //-   :title="alertType"
    //-   :message="alertMessage"
    //-   actionLabel="Delete"
    //-   cancelLabel="Cancel"
    //-   @action="performAction"
    //-   @cancel="cancelAction"
    //- )
    //- template
    .text-center.pa-4
      v-dialog(v-model="alertVisible" max-width="400" persistent)
        //- template(v-slot:activator="{ props: activatorProps }")
        //- v-btn(v-bind="activatorProps") Open Dialog
        v-card(prepend-icon="mdi-map-marker" 
          text="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running." 
          title="Use Google's location service?")
          v-card-title {{alertMessage}}
          v-spacer
          v-card-actions
            v-btn(@click="alertBtn") continue  
            //- v-btn(@click="dialog = false") Agree

</template>
  
  <script>
  import BorderGradient from "@/components/_design/BorderGradient.component";
  
  import SideBarL from "@/components/SideBarL.component";
  import SideBarR from "@/components/SideBarR.component";
  import Visualisation from "@/components/Visualisation.component";
  import axios from 'axios';
// import Alert from "@/components/Alert";
  
  export default {
    name: "Main",
    metaInfo: {
      title: "Visualisation",
    },
    components: {
      SideBarL,
      SideBarR,
      Visualisation,
      BorderGradient,
    },
    data: () => ({
      isVisibleSideBarL: false,
      isVisibleSideBarR: false,

      profilId: localStorage.getItem('profileId'), // Replace with the actual profile ID
      alertVisible: false,
      alertMessage: '',
      profileExists: false
    }),
    methods: {

      triggerAlert(profileExists, message) {
      this.alertMessage = message;
      this.alertVisible = true;

      if (profileExists) {
        this.alertVisible = false;
        this.profileExists = true;
      } else {
        this.profileExists = false;
      }
      
    },

    alertBtn() {
      if(this.profileExists) {
      this.alertVisible = false;

      } else {
        // this.$router.push({ name: 'Avatar' });
        this.$router.push({ name: 'firstConnection' });

      }
    },


    async checkAvatar() {
      try {
        // alert("checkAvatar")
        const response = await axios.get('https://api.modalive.fr/v1/avatarProfileExist', {
          params: { profilId: this.profilId },
        });

        if (response.data.exists) {
          this.triggerAlert(response.data.exists, 'Avatar exists for the profile! You can dress now.');
        } else {
          this.triggerAlert(response.data.exists, 'No avatar found for this profile. Proceed to measurements');
        }
      } catch (err) {
        this.triggerAlert(
          false,
          err.response?.data?.error || 'An error occurred while checking the avatar.'
        );
      }
    },

      toggleSideBarL() {
        if (this.isVisibleSideBarR)
          this.isVisibleSideBarR = !this.isVisibleSideBarR;
        this.isVisibleSideBarL = !this.isVisibleSideBarL;
      },
      toggleSideBarR() {
        if (this.isVisibleSideBarL)
          this.isVisibleSideBarL = !this.isVisibleSideBarL;
        this.isVisibleSideBarR = !this.isVisibleSideBarR;
      },
    },
    async mounted() {

    try {
     await this.checkAvatar();
      
    } catch (error) {
      console.log('====================================');
      console.log('mounted ERROR --- checkAvatar: ' + error);
      console.log('====================================');
    }

  },
  };
  </script>
  
  <style lang="scss">
  #mainView {
    position: relative;
    background-color: var(--v-lGrey-base);
    height: calc(100vh - 56px);
    #visualisation {
      position: fixed;
      width: 100%;
      // background-color: red;
    }
    .responsiveButton,
    .side-bar {
      position: absolute;
      z-index: 50;
    }
    .side-bar {
      height: calc(100vh - 56px);
      width: 350px;
  
    }
    .side-bar-s {
      height: calc(100vh - 56px);
      width: 20px;
      opacity: 0.7;
    }
    .size-s{
      font-size: 1px;
    }
    .responsiveButton {
      cursor: pointer;
      top: 15%;
      height: 50%;
      p {
        height: 100%;
      }
      * {
        font-weight: bolder;
      }
    }
    .responsiveButtonL {
      left: 5px;
      &.sideBarLShow {
        left: 350px;
      }
    }
    .responsiveButtonR {
      right: 5px;
      &.sideBarRShow {
        right: 350px;
      }
    }
    @media (min-width: 1850px) {
    .side-bar {
      width:450px;
    }
  }
  }
  
  
  
  @media (max-width: 600px) {
    .responsiveButtonL.sideBarLShow {
      left: 350px !important;
    }
    .responsiveButtonR.sideBarRShow {
      right: 350px !important;
    }
  }
  
  @media (max-width: 450px) {
    .responsiveButton {
      &.hidden-xxs-and-down {
        display: none;
      }
    }
  }
  
  
  </style>
  