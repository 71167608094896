import Peer from "../../../services/visualisation/peer.service";
import SignalingClient from "../../../services/visualisation/signaling.service";
import commands from "./communication/commands";

const state = {
  peers: [],
  peer: null,
  signalingClient: null,
};

const getters = {
  peerClient: (state) => state.peer,
  signalingClient: (state) => state.signalingClient,
};

const mutations = {
  SET_SIGNALING_SERVER: (state) => {
    state.signalingClient = new SignalingClient();
    console.log('=================SET_SIGNALING_SERVER===================');
    console.log('SET_SIGNALING_SERVER called', state.signalingClient);
    console.log('====================================');
  },
  SET_CLIENT_PEER: (state, options) => {
    state.peer = new Peer(options.socket, options.comp);
    state.peers.push(state.peer);
    console.log('=================SET_CLIENT_PEER: peer===================');
    console.log(state.peer);
    console.log('====================================');
  },
};

const actions = {
  initCommunication: ({ commit, getters }, component) => {
    commit('SET_SIGNALING_SERVER');
    const signalingSocket = getters.signalingClient.getSocket();

    // EVENT
    signalingSocket.on('socket ready', () => { console.log('Socket ready  <=='); });

    signalingSocket.on('consumer ready', () => {
      console.log('Consumer ready  <==');
      commit('SET_CLIENT_PEER', { 
        socket: signalingSocket,
        comp: component 
      });
      
    });

    // console.log('=================initCommunication: peer===================');
    // console.log(state.peer);
    // console.log('====================================');

    signalingSocket.on('consumerPeerAnswer', (data) => {
      console.log('consumerPeerAnswer Receive  <==');
      alert('consumerPeerAnswer Receive  <==');
      getters.peerClient.signal(data);
    });
  },
  emitClientReady: ({ getters }) => {
    getters.signalingClient.clientReady();
  },
  disconnectSocket: ({ getters }) => {
    const signalingSocket = getters.signalingClient.getSocket();
    // signalingSocket.disconnect();
    signalingSocket.emit('manual-disconnect');
    console.log('Peer manual disconnection');
  },
  stopStreamSoct: ({ getters }) => {
    const peerClient = getters.peerClient;
    if (peerClient && peerClient.stream) {
      peerClient.stream.getTracks().forEach(track => track.stop()); // Stops all tracks
      peerClient.stream = null; // Reset the stream reference
      console.log('Stream stopped');
    } else {
      console.warn('No active stream to stop');
    }
  },

  sendPoseRequest: ({ getters }, data) => {
    // const signalingSocket = getters.signalingClient.getSocket();
    // signalingSocket.emit('PoseRequest');
    // console.log('sendPoseRequest emitted with data:');
    const peerClient = getters.peerClient;
    if (peerClient) {
      peerClient.sendData({
        type: 'PoseRequest',
        data: data
      });
    } else {
      console.error('Peer client is not initialized. Cannot send morphoData.');
    }
  },

  sendMorphoRequest: ({ getters }, data) => {
    const peerClient = getters.peerClient;
    if (peerClient) {
      peerClient.sendData({
        type: 'MorphoRequest',
        data: data
      });
    } else {
      console.error('Peer client is not initialized. Cannot send morphoData.');
    }
  },

  sendMorphoData: ({ getters }, morphoData) => {
    const peerClient = getters.peerClient;
    if (peerClient) {
      peerClient.sendMorphoData(morphoData);
    } else {
      console.error('Peer client is not initialized. Cannot send morphoData.');
    }
  },
  

  
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    commands,
  },
};
